<template>
  <div class="auth-wrapper auth-v1 px-2 mainbg">
    <div class="auth-inner py-2 iner">
      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img
            src="ma5.png"
            alt="logo"
            height="85"
          >
        </b-link>

        <!-- กรอกเบอร์โทรศัพท์ -->
        <div v-if="!ref">
          <div class="mt-1">
            <b-form-group>
              <label>เบอร์โทรศัพท์</label>
              <b-form-input
                v-model="tel"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="10"
                placeholder="กรุณากรอกเบอร์โทรศัพท์"
                type="number"
                size="lg"
              />
              <small
                class="text-danger"
              >*กรอกเบอร์เพื่อส่ง OTP ไปยังเบอร์มือถือของคุณ</small>
            </b-form-group>
          </div>

          <div class="text-center mt-2">
            <b-button
              variant="primary"
              block
              @click="getOTP"
            >
              รับ OTP
            </b-button>
          </div>
        </div>

        <!-- OTP -->
        <!-- <div v-if="ref && !match"> -->
        <div v-if="ref && !match">
          <!-- <div> -->
          <div class="mt-2">
            <b-form-group>
              <label
                class="fg"
              >กรอก OTP เพื่อยืนยัน
                <small class="text-danger">ref : {{ ref }}</small></label>
              <!-- <b-form-input
                    v-model="otp"
                    placeholder=""
                    type="number"
                  /> -->
              <div style="margin: auto; justify-content: center; display: flex">
                <otp-input
                  v-model="otp"
                  class="style-1"
                  :length="6"
                  type="password"
                  pattern="[^0-9]+"
                  :ignore-pattern="false"
                  :size="28"
                  @valid="isTokenComplete"
                />
              </div>
              <small
                class="text-danger"
              >*กรอกรหัส OTP เพื่อยืนยันดำเนินการต่อไป</small>
            </b-form-group>
          </div>
          <div class="text-center mt-2">
            <b-button
              variant="primary"
              block
              @click="checkOTP"
            >
              ยืนยัน OTP
            </b-button>
          </div>
        </div>

        <!-- Confirm -->
        <div v-if="match">
          <!-- <div> -->
          <validation-observer ref="registerForm">
            <div class="mt-2">
              <b-row>
                <b-col cols="6">
                  <b-form-group>
                    <label class="fg">ชื่อ</label>
                    <validation-provider
                      #default="{ errors }"
                      name="ชื่อ"
                      rules="required"
                    >
                      <b-form-input
                        v-model="name"
                        placeholder="กรุณากรอกชื่อ"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        size="lg"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <label class="fg">นามสกุล</label>
                    <validation-provider
                      #default="{ errors }"
                      name="นามสกุล"
                      rules="required"
                    >
                      <b-form-input
                        v-model="surname"
                        :state="errors.length > 0 ? false : null"
                        placeholder="กรุณากรอกนามสกุล"
                        type="text"
                        size="lg"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <small>*กรอกชื่อ-นามสกุล ให้ตรงกับชื่อบัญชี หรือ
                ทรูมันนี่วอลเล็ท</small>

              <b-form-checkbox
                v-model="wallet"
                checked="fasle"
                name="check-button"
                switch
                inline
                class="custom-control-warning"
              >
                ทรูมันนี่วอลเล็ท
              </b-form-checkbox>
              <b-form-group v-if="wallet === true">
                <label class="fg">กรอกหมายเลขทรูมันนี่วอลเล็ท</label>
                <b-form-input
                  v-model="trueaccno"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="10"
                  placeholder="กรุณากรอกหมายเลขทรูมันนี่วอลเล็ท"
                  size="lg"
                  type="number"
                />
              </b-form-group>
              <dev v-else>
                <b-form-group>
                  <label class="fg">เลือกธนาคาร</label>
                  <!-- <b-form-select
                    v-model="s_bank"
                    style="background-color: #141414"
                    :options="bank"
                    size="lg"
                    type="number"
                  /> -->
                  <b-dropdown
                    :text="selected ? selected.name : 'กรุณาเลือกธนาคาร'"
                    block
                    variant="primary"
                    menu-class="w-100"
                  >
                    <b-dropdown-item
                      v-for="option in bank"
                      :key="option.bankcode"
                      class="sty"
                      :style="`background-color: ${option.bg};`"
                      @click="select(option)"
                    >
                      <img
                        :src="`/bankIcon/${option.path_photo}`"
                        width="20"
                      >
                      <span style="color: #ffffff">
                        {{ option.name }}
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-form-group>
                <b-form-group>
                  <label class="fg">กรอกบัญชีธนาคาร</label>
                  <validation-provider
                    #default="{ errors }"
                    name="บัญชีธนาคาร"
                    rules="required"
                  >
                    <b-form-input
                      v-model="accno"
                      :state="errors.length > 0 ? false : null"
                      placeholder="กรุณากรอกเลขบัญชี"
                      size="lg"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </dev>
              <b-form-group>
                <label class="fg">ยูสเซอร์</label>
                <p>{{ username }}</p>

                <!-- <small>*เพื่อใช้ ล๊อกอิน เข้าเล่นเกมส์</small> -->
              </b-form-group>
              <b-form-group>
                <label class="fg">กรอกรหัสผ่าน</label>
                <validation-provider
                  #default="{ errors }"
                  name="รหัสผ่าน"
                  vid="Password"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="basic-password1"
                      v-model="password"
                      size="lg"
                      :state="errors.length > 0 ? false : null"
                      :type="passwordFieldType"
                      placeholder="กรุณากรอกรหัสผ่าน"
                    />
                    <b-input-group-append
                      is-text
                      size="lg"
                    >
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label class="fg">ยืนยันรหัสผ่าน</label>
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-form-input
                    id="ac-password"
                    v-model="passValue"
                    :state="errors.length > 0 ? false : null"
                    type="password"
                    size="lg"
                    placeholder="ยืนยันรหัสผ่านอีกครั้ง"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <label class="fg">รู้จักเราผ่านช่องทาง :</label>
                <b-form-select
                  v-model="knowby"
                  size="lg"
                  :options="options"
                  placeholder=""
                />
              </b-form-group>
              <b-form-group>
                <label class="fg">รหัสแนะนำ</label>
                <b-form-input
                  v-model="invited_by"
                  placeholder="กรอกรหัสแนะนำ"
                  size="lg"
                  type="text"
                />
              </b-form-group>
            </div>

            <div class="text-center mt-1">
              <b-button
                variant="primary"
                block
                @click.prevent="validationForm"
              >
                ยืนยันข้อมูล
              </b-button>
            </div>
          </validation-observer>
        </div>

        <b-card-text class="text-center mt-2">
          <span>หากมีบัญชีผู้ใช้แล้ว? </span>
          <b-link :to="{ name: 'auth-login-v1' }">
            <span>เข้าสู่ระบบ</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BLink,
  BCardText,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormSelect,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    // BSV
    BCard,
    BLink,
    BCardText,
    BFormSelect,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      passValue: '',
      Ip: null,
      wallet: '',
      regEmail: '',
      username: '',
      password: '',
      status: '',
      ref: '',
      match: '',
      // validation rules
      name: '',
      required,
      tel: '',
      trueaccno: '',
      otp: '',
      selected: null,
      s_bank: null,
      accno: '',
      knowby: null,
      promo: '',
      promotion: '',
      invited_by: '',
      bank: [{ value: null, text: 'กรุณาเลือกธนาคาร' }],
      selects: null,
      options: [
        { value: null, text: 'คุณรู้จักเราผ่านช่องทางใด' },
        { value: 1, text: 'Facebook' },
        { value: 2, text: 'Youtube' },
        { value: 3, text: 'Tik Tok' },
        { value: 4, text: 'Google' },
        { value: 5, text: 'SMS' },
        { value: 6, text: 'เพื่อนแนะนำ' },
      ],
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordConToggleIcon() {
      return this.passwordConFieldType === 'passValue'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  mounted() {
    this.getBank()
    if (this.$route.query.ref) {
      this.knowby = 6
      this.invited_by = this.$route.query.ref
    }

    // console.log(this.$route.query.ref)
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          if (this.wallet === true) {
            this.s_bank = 'truewallet'
            this.accno = null
          } else {
            this.trueaccno = null
          }
          const formData = {
            role: 0,
            tel: this.tel,
            fname: this.name,
            sname: this.surname,
            password: this.password,
            bank: this.s_bank,
            acc_no: this.accno,
            trueaccno: this.trueaccno,
            knowby: this.knowby,
            username: this.username,
            invited_by: this.invited_by,
          }
          console.log(formData)
          this.$http
            .post('https://api.ma5lotto.com/api/auth/register', formData)
            .then(() => {
              // console.log(res)
              useJwt
                .login({
                  email: this.tel,
                  password: this.password,
                  Detail: {
                    Ip: this.Ip,
                    UserAgent: navigator.userAgent,
                    Platform: navigator.platform,
                  },
                })
                .then(response => {
                  const userData = response.data
                  useJwt.setToken(response.data.token)
                  useJwt.setRefreshToken(response.data.refreshToken)
                  localStorage.setItem('userData', JSON.stringify(userData))
                  this.$ability.update(userData.ability)
                  this.$router
                    .push(getHomeRouteForLoggedInUser(userData.role))
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `มหาล๊อตโต ยินดีต้อนครับค่ะ   คุณ${
                            `${userData.fname} ${userData.sname}`
                            || userData.username
                          }`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: '',
                        },
                      })
                    })
                    .catch(error => {
                      this.$refs.loginForm.setErrors(error.response.data.error)
                    })
                })
                .catch(error => {
                  this.$refs.loginForm.setErrors(error.response.data.error)
                })
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    async GetIp() {
      try {
        const { data: res } = await axios.get('https://api.ipify.org')
        this.Ip = res
      } catch (e) {
        this.Ip = null
      }
    },
    getOTP() {
      // this.SwalError(
      //   'ปิดรับสมัครชั่วคราว เนื่องจากรอบของการแจกเครดิตฟรีหมดแล้วค่ะ',
      // )
      const formData = {
        tels: this.tel,
      }
      this.$http
        .post('https://api.ma5lotto.com/api/otp/get', formData)
        .then(response => {
          this.ref = response.data.ref
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    checkOTP() {
      const formData = {
        tels: this.tel,
        ref: this.ref,
        otps: this.otp,
      }
      this.$http
        .post('https://api.ma5lotto.com/api/otp/check', formData)
        .then(response => {
          this.match = response.data.match
          this.username = response.data.username
          // this.Success('ยืนยัน OTP สำเร็จ')
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    select(option) {
      this.selected = option
      this.s_bank = option.bankcode
    },
    getBank() {
      this.$http
        .get('https://api.ma5lotto.com/api/bank/list')
        .then(response => {
          // console.log(response.data)
          this.bank = response.data
        })
        .catch(error => {
          console.log(error.response.data.message)
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: 'SUCCESS',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<span>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style scoped>
.sty {
  color: #ffffff;
  background-color: #82868b;
  border-bottom: 1px solid #fff;
}
.iner {
  position: relative;
  z-index: 2;
}
.mainbg {
  background: rgb(58, 8, 8);
  background: linear-gradient(
    158deg,
    rgb(21, 31, 20) 0%,
    rgb(224, 218, 218) 48%,
    rgb(31, 118, 5) 96%
  );
  width: 100%;
  min-height: 100vh;
  left: 0;
  position: relative;
  z-index: 1;
  display: flex;
  text-align: inherit;
}
.mainbg:after,
.mainbg:before {
  content: '';
  width: 100%;
  position: absolute;
  z-index: 0;
}
.mainbg:before {
  background: url(/gradiant-top.svg) top no-repeat;
  background-size: 100% auto;
  min-height: 100vh;
  height: auto;
}
.mainbg:after {
  background: url(/gradiant-bottom.svg) bottom no-repeat;
  background-size: 60% auto;
  bottom: 0;
  height: 100vh;
}
label {
  font-size: 1.149rem;
}
</style>

<style lang="scss">
@import url('https://unpkg.com/@8bu/vue-otp-input@1.1.6/dist/vue-otp-input.css');
@import '@core/scss/vue/pages/page-auth.scss';
.style-1 {
  // background-color: #141414;
  .otp-input-8-field input {
    border-radius: 4px;
    color: #000000;
    height: 42px;
    width: 42px;
    margin-left: 2px;
    margin-right: 2px;
    background-color: #ffffff;
    border: 1px solid #ad0000;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
</style>
